import Cookies from 'js-cookie';

// CONSTANTS
import { cloudfront } from '@/constants/common';
import { LOCATIONS_IN_CONTENT } from '@/constants/locations';
import { triggered } from '@/constants/analytics';

// UTILITY
import bugsnag from '@/utility/bugsnagClient';
import localStore from '@/utility/store';
import { localStorageAvailable } from '@/utility/common';
import { getDomainData } from '@/utility/partner';
import { getReferrer } from '@/utility/routes';

// STORE
import store from '@/store/configureStore';
import { AnalyticsActions } from '@/store/actions';

const env = process.env.REACT_APP_ENV;
const testOrLife = env !== 'production' ? 'test' : 'live';
const { domain, originPath, isPartnerDomain } = getDomainData();
const myBundleLogo = `${cloudfront}/assets/images/mybundle-logo.png`;

export const defAnalyticsData = {
  test_or_live: testOrLife,
  domain,
  pageTitle: '',
  query_params: '',
  mybundle_id: '',
  partner_id: '',
  unique_id: '',
  referrer: '',
};

function getCookies(key) {
  let res = '';

  try {
    res = Cookies.get(key);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
  }

  return res;
}

export function getDefaultAnalyticsData() {
  const state = store.getState();

  const user = state.auth.user;
  const partnerId = state.partner.partner_id;
  const unique_id = state.auth.unique_id;
  const anonymousId = state.auth.anonymousId;
  const referrer = getReferrer();

  return {
    ...defAnalyticsData,
    pageTitle: document.title || '',
    query_params: window.location.search,
    mybundle_id: user?.id || '',
    partner_id: partnerId || '',
    unique_id: unique_id || anonymousId || '',
    referrer,
  };
}

export const analyticsSendToFivetran = (action = '', properties = {}) => {
  const anlStoredParam = localStorageAvailable() ? localStore.get('anl') : '';
  const anlOff = (anlStoredParam && anlStoredParam === 'off') || false;

  if (anlOff) return;

  const referrer = getReferrer();
  const page_url = window.location?.pathname;
  const data = {
    action,
    page_url,
    referrer,
    properties,
    unique_id: properties.unique_id || '',
  };

  store.dispatch(AnalyticsActions.analyticsSendToFivetran.action({ data }));
};

export const analyticsGetUserId = () => {
  let userId = '';

  if (window.analytics && window.analytics.user) {
    userId = window.analytics.user().anonymousId();
  }

  return userId;
};

export const getExternalUrlParams = (data = {}) => {
  const defaultData = getDefaultAnalyticsData();

  const partner_id = data.partner_id || defaultData.partner_id || 0;
  const unique_id = data.unique_id || defaultData.unique_id || `partner-${partner_id}`;
  const utm_source = getCookies('utm_source');

  return {
    ...defaultData,
    unique_id: unique_id || 'discover-store',
    source: data.source || utm_source || defaultData.referrer,
    page: data.page || window.location?.pathname || '',
    phone_number: data.phone || '',
    stripped_unique_id: data.unique_id?.replace('.', '') || '',
    partner_id,
  };
};

export const customAnalyticsEvent = (action, data = {}) => {
  const defaultData = getDefaultAnalyticsData();

  const properties = {
    ...defaultData,
    ...data,
  };

  if (window.analytics) {
    window.analytics.track(action, properties);
  } else {
    bugsnag.notify(
      new Error(`Unable to send statistics via segment, action - ${action}`),
      event => {
        event.addMetadata('props', properties);
      },
    );
  }

  analyticsSendToFivetran(action, properties);
};

/* COMMON EVENTS */

export const analyticsPageLoaded = (pageName = null, data = {}) => {
  const defaultData = getDefaultAnalyticsData();

  if (window.analytics) {
    setTimeout(() => {
      window.analytics.page(window.location?.pathname || pageName, {
        ...defaultData,
        ...data,
      });
    }, 0);
  }
};

export const analyticsIdentify = (identifier, properties = {}, options = {}) => {
  if (window.analytics && identifier) {
    localStore.remove('ajs_user_traits');

    const {
      partner: { data: partnerData },
      app: { partner: partnerSlug },
    } = store.getState();
    const defaultData = getDefaultAnalyticsData();
    const hasPartner = partnerData?.slug || partnerSlug;
    const textOverwrites = partnerData?.text_overwrites || {};
    let partner_mybundle_url = originPath;
    let partner_name = 'MyBundle.TV';
    let partner_header_image = myBundleLogo;

    if (hasPartner) {
      partner_mybundle_url = isPartnerDomain
        ? originPath
        : `${partner_mybundle_url}/${partnerSlug}`;
      partner_name = textOverwrites.partner_name_klaviyo || partnerData.partner_name;
      partner_header_image = partnerData.email_logo || partnerData.logo;
    }

    window.analytics.identify(
      identifier,
      {
        ...defaultData,
        mybundle_id: properties.user_id || defaultData.mybundle_id,
        PARTNERID: partnerData?.slug || '',
        partner_mybundle_url,
        partner_name,
        partner_header_image,
        ...properties,
      },
      options,
    );
  }
};

export const analyticsAuth = (data = {}) => {
  const properties = {
    user_id: data.user_id,
    email: data.email,
    HASACC: true,
  };

  analyticsIdentify(data.email, properties);
};

export const analyticsTrackTreatment = (data = {}) => {
  const action = 'Track Treatment';
  const defaultData = getDefaultAnalyticsData();
  const state = store.getState();

  const anonymousId = state.auth.anonymousId;

  const properties = {
    ...defaultData,
    ...data,
    action: data.action || 'Identify Treatment',
    client: anonymousId || 'default',
    split: data.split || '',
    treatment: data.treatment || '',
    slot: data.slot || '',
    bundle_id: data.bundle_id || '',
    location: data.location || '',
    service_id: data.service_id || '',
    service_name: data.service_name || '',
    href: data.href || '',
    app_id: data.app_id ?? '',
    other: data.other || '',
    type: data.type || '',
    is_affiliate: data.is_affiliate || false,
    partner: data.partner || state.partner.data.slug || '',
    partner_id: data.partner_id || defaultData.partner_id || '',
  };

  if (window.analytics) {
    window.analytics.track(action, properties);
  }
};

export const externalLink = (data = {}) => {
  const action = 'External Link Clicked';
  const defaultData = getDefaultAnalyticsData();
  const state = store.getState();

  const partner = state.partner.data;
  const partner_id = data.partner_id || defaultData.partner_id || 0;
  const unique_id = data.unique_id || defaultData.unique_id || `partner-${partner_id}`;
  let page = data.page_name || window.location?.pathname || '';

  if (partner.slug && page.includes(`/${partner.slug}`)) {
    page = page.replace(`/${partner.slug}`, '');
  }

  const properties = {
    ...data,
    slot: data.slot || '',
    bundle_id: data.bundle_id || '',
    location: data.location || '',
    service_id: data.service_id || '',
    service_name: data.service_name || '',
    href: data.href || '',
    app_id: data.app_id ?? '',
    other: data.other || '',
    type: data.type || '',
    page,
    is_affiliate: data.is_affiliate || false,
    partner: data.partner || partner.slug || '',
    partner_id,
    unique_id,
    content_id: data.content_id || '',
    content_title: data.content_title || '',
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsInternalLinks = (data = {}) => {
  if (typeof data.location === 'string') {
    const action = 'Internal Link Clicked';
    const defaultData = getDefaultAnalyticsData();
    const state = store.getState();
    const anonymousId = state.auth.anonymousId;

    const properties = {
      annonymous_id: anonymousId,
      unique_id: data.unique_id || defaultData.unique_id,
      from_page_path: data.from_page_path || '',
      from_search_params: data.from_search_params || '',
      to_page_path: data.to_page_path || '',
      to_search_params: data.to_search_params ? `?${data.to_search_params}` : '',
      partner_id: data.partner_id || '',
      location: data.location || LOCATIONS_IN_CONTENT,
    };

    customAnalyticsEvent(action, properties);
  }
};

export const analyticsSignUpModalShown = (data = {}) => {
  const action = 'Signup Modal Shown';
  const defaultData = getDefaultAnalyticsData();

  const content_id =
    data.triggered === triggered.app ? { app_id: data.app_id } : { content_id: data.content_id };

  const properties = {
    ...content_id,
    triggered: data.triggered,
    action: data.action,
    partner_id: data.partner_id || defaultData.partner_id,
    location: data.location || '',
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsAuthFromModal = (authAction, data = {}) => {
  const action = `${authAction} From Modal`;
  const defaultData = getDefaultAnalyticsData();

  const properties = {
    user_id: data.user_id,
    partner_id: data.partner_id || defaultData.partner_id,
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsShareClick = (data = {}) => {
  const action = 'Clicked Share';

  const properties = {
    location: data.location || '',
    type: data.type || '',
    unique_id: data.unique_id || '',
    url: data.url,
    ...data,
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsAddedToWatchlist = (data = {}) => {
  const action = 'Added Content To Watchlist';
  const defaultData = getDefaultAnalyticsData();

  const properties = {
    content_id: data.content_id,
    content_name: data.content_name,
    user_id: +data.user_id || '',
    partner_id: data.partner_id || defaultData.partner_id || 0,
    page: window.location?.pathname || '',
    location: data.location || '', // (hover, modal, detail page)
    swimlane_name: data.swimlane_name || '',
    swimlane_id: data.swimlane_id || '',
    card_index: data.card_index ?? '',
    swimlane_number_on_page: data.swimlane_number_on_page || '',
    from_url: data.from_url || false,
  };

  customAnalyticsEvent(action, properties);
};

/**
 * analyticsUpdateAppListStatus - action: string (Added App To Profile, Saved App For Later)
 */
export const analyticsUpdateAppListStatus = (action, data = {}) => {
  const defaultData = getDefaultAnalyticsData();

  const properties = {
    app_id: data.app_id,
    app_name: data.app_name,
    user_id: data.user_id,
    location: data.location,
    partner_id: data.partner_id || defaultData.partner_id,
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsHoverOnApp = (data = {}) => {
  const action = 'Hover on an App';
  const defaultData = getDefaultAnalyticsData();

  const properties = {
    ...defaultData,
    app_name: data.app_name || '',
    app_id: data.app_id || '',
    swimlane_name: data.swimlane_name || '',
    swimlane_id: data.swimlane_id || '',
    card_index: data.card_index ?? '',
    swimlane_number_on_page: data.swimlane_number_on_page || '',
    page: window.location?.pathname || '',
  };

  if (window.analytics) {
    window.analytics.track(action, properties);
  }
};

export const analyticsClickedToApp = (data = {}) => {
  const action = 'App Clicked';
  const defaultData = getDefaultAnalyticsData();
  const state = store.getState();

  const anonymousId = state.auth.anonymousId;

  const properties = {
    ...data,
    annonymous_id: anonymousId,
    unique_id: data.unique_id || defaultData.unique_id,
    from_page_path: data.from_page_path || '',
    from_search_params: data.from_search_params || '',
    to_page_path: data.to_page_path || '',
    to_search_params: data.to_search_params ? `?${data.to_search_params}` : '',
    partner_id: data.partner_id || defaultData.partner_id,
    location: data.location || LOCATIONS_IN_CONTENT,
    app_name: data.app_name || '',
    app_id: data.app_id || '',
    swimlane_name: data.swimlane_name || '',
    swimlane_id: data.swimlane_id || '',
    card_index: data.card_index ?? '',
    swimlane_number_on_page: data.swimlane_number_on_page || '',
    page: window.location?.pathname || '',
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsHoverOnContent = (data = {}) => {
  const action = 'Hover on Content';
  const defaultData = getDefaultAnalyticsData();
  const state = store.getState();

  const user = state.auth.user;

  const properties = {
    ...defaultData,
    content_id: data.content_id || '',
    content_title: data.content_title || '',
    card_index: data.card_index ?? '',
    page: window.location?.pathname || '',
    swimlane_id: data.swimlane_id || '',
    swimlane_name: data.swimlane_name || '',
    swimlane_number_on_page: data.swimlane_number_on_page || '',
    partner_id: data.partner_id || defaultData.partner_id || 0,
    user_id: data.user_id || user?.id || '',
  };

  if (window.analytics) {
    window.analytics.track(action, properties);
  }
};

export const analyticsClickedOnContent = (data = {}) => {
  const action = 'Clicked on Content';
  const defaultData = getDefaultAnalyticsData();
  const state = store.getState();

  const user = state.auth.user;

  const properties = {
    content_id: data.content_id || '',
    content_title: data.content_title || '',
    swimlane_name: data.swimlane_name || '',
    swimlane_id: data.swimlane_id || '',
    card_index: data.card_index ?? '',
    swimlane_number_on_page: data.swimlane_number_on_page || '',
    page: window.location?.pathname || '',
    partner_id: data.partner_id || defaultData.partner_id || 0,
    user_id: data.user_id || user?.id || '',
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsSwimlaneNavigation = (data = {}) => {
  const action = 'Viewed More Content on Swimlane';

  const properties = {
    swimlane_name: data.swimlane_name || '',
    swimlane_id: data.swimlane_id || '',
    swimlane_number_on_page: data.swimlane_number_on_page || '',
    swimlane_slot: data.swimlane_slot || '',
    page: window.location?.pathname || '',
    items_shifted: data.items_shifted,
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsSwimlaneSeeAll = (data = {}) => {
  const action = 'Clicked See All on Swimlane';

  const properties = {
    swimlane_name: data.swimlane_name || '',
    swimlane_id: data.swimlane_id || '',
    swimlane_number_on_page: data.swimlane_number_on_page || '',
    swimlane_slot: data.swimlane_slot || '',
    page: window.location?.pathname || '',
    location: data.location || '',
    type: data.type || '',
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsEmailClicked = (data = {}) => {
  const action = 'Tracking for Email Click';

  const properties = {
    page: window.location?.pathname || '',
    location: data.location || '',
    type: data.type || '',
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsReportModalShown = (data = {}) => {
  const action = 'Report Modal Shown';
  const defaultData = getDefaultAnalyticsData();

  const properties = {
    content_title: data.content_title,
    content_id: data.entity_id,
    partner_id: data.partner_id || defaultData.partner_id || 0,
    location: data.location || '',
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsRecievedError = (data = {}) => {
  const action = 'Recieved Error';
  const defaultData = getDefaultAnalyticsData();
  const state = store.getState();

  const anonymousId = state.auth.anonymousId;

  const properties = {
    ...defaultData,
    annonymous_id: anonymousId,
    page: window.location?.pathname || '',
    url: data.url || '',
    status: data.status || '',
    message: data.message || '',
    method: data.method || '',
    params: data.params || '',
  };

  if (window.analytics) {
    window.analytics.track(action, properties);
  }
};

export const analyticsStartFormLink = (data = {}) => {
  const action = 'Start Form Link';
  const defaultData = getDefaultAnalyticsData();

  const properties = {
    ...defaultData,
    location: data.location,
    type: data.type,
  };

  if (window.analytics) {
    window.analytics.track(action, properties);
  }
};

/* HOME EVENTS */

export const analyticsHomepageHeroAccordionClicked = (data = {}) => {
  const action = 'Homepage Hero Accordion Clicked';
  const defaultData = getDefaultAnalyticsData();
  const state = store.getState();

  const anonymousId = state.auth.anonymousId;

  const properties = {
    ...defaultData,
    annonymous_id: anonymousId,
    page: window.location?.pathname || '',
    item: data.item,
  };

  if (window.analytics) {
    window.analytics.track(action, properties);
  }
};

/* FMB AND INTERNET OFFERS EVENTS */

export const analyticsFormSubmissionStarted = (data = {}) => {
  const action = 'Form Submission Started';
  const defaultData = getDefaultAnalyticsData();

  const properties = {
    unique_id: data.unique_id || defaultData.unique_id,
    parent_id: data.parent_id,
    form_version: data.form_version,
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsLoadedQuestion = data => {
  const action = 'Loaded Question';
  const defaultData = getDefaultAnalyticsData();

  const properties = {
    questionId: data.question_slug,
    partner_extra: data.partner_extra || false,
    partner: data.partner || '',
    partner_id: data.partner_id || defaultData.partner_id,
    flow: data.flow || '',
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsAnswerSubmitted = (event, data = {}) => {
  const action = 'Answer Submitted';

  const properties = {
    type: event.target.type || 'radio',
    answer: event.target?.dataset?.text || '',
    questionId: event.target.name === 'dma_id' ? 'county' : event.target.name,
    flow: data.flow || '',
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsAnswerSubmittedEmail = data => {
  const action = 'Answer Submitted';

  const properties = {
    email: data.email,
    questionId: 'email',
    type: 'email',
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsFormSubmitted = () => {
  const action = 'Form Submitted';

  customAnalyticsEvent(action, {});
};

export const analyticsAnswerClicked = (event, data = {}) => {
  const action = 'Answer Clicked';

  const properties = {
    type: event.target.type || 'checkbox',
    answer: event.target?.dataset?.text || '',
    questionId: event.target.name,
    flow: data.flow || '',
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsQuestionSkipped = data => {
  const action = 'Question Skipped';
  const defaultData = getDefaultAnalyticsData();

  const properties = {
    questionId: data.question_slug,
    partner: data.partner || '',
    partner_id: data.partner_id || defaultData.partner_id,
    flow: data.flow || '',
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsQuestionAnswered = data => {
  const action = 'Answered Question';
  const defaultData = getDefaultAnalyticsData();

  const properties = {
    questionId: data.question_slug,
    partner: data.partner || '',
    partner_id: data.partner_id || defaultData.partner_id,
  };

  customAnalyticsEvent(action, properties);
};

// Using also for PROMOTION
export const analyticsDynamicEvent = (data = {}) => {
  const action = 'Dynamic Event';

  const properties = {
    activity_name: data.activity_name || '',
    feature_1: data.feature_1 || '',
    feature_2: data.feature_2 || '',
    feature_3: data.feature_3 || '',
    id_of_item: data.id_of_item || '',
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsInternetOptionShown = (data = {}) => {
  const action = 'Internet option being shown';
  const defaultData = getDefaultAnalyticsData();
  const state = store.getState();

  const properties = {
    ...data,
    partner: data.partner || state.partner.data.slug || '',
    partner_id: data.partner_id || defaultData.partner_id,
    bundle_id: data.bundle_id || '',
    slot: data.slot || '',
    name: data.name || '',
    url: data.url || '',
    price: data.price || '',
    unique_id: data.unique_id || defaultData.unique_id,
    internet_option_id: data.internet_option_id || '',
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsClickInternetOption = (data = {}) => {
  const action = 'Clicking on internet link';
  const defaultData = getDefaultAnalyticsData();
  const state = store.getState();

  const properties = {
    partner: data.partner || state.partner.data.slug || '',
    partner_id: data.partner_id || defaultData.partner_id,
    provider_name: data.provider_name || '',
    url: data.url || '',
    id: data.id || '',
    form_submission_internet_option_id: data.form_submission_internet_option_id || '',
    unique_id: data.unique_id || defaultData.unique_id,
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsClickedCreateProfile = (data = {}) => {
  const action = 'Clicked Create Profile';
  const state = store.getState();

  const anonymousId = state.auth.anonymousId;

  const properties = {
    annonymous_id: anonymousId,
    page: window.location?.pathname || '',
    location: data.location || '',
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsBundlesLoaded = (data = {}) => {
  const action = 'The Bundles Loaded';
  const defaultData = getDefaultAnalyticsData();

  const properties = {
    ...defaultData,
    bundle_1: data.bundle_1,
    bundle_2: data.bundle_2,
    bundle_3: data.bundle_3,
    bundle_4: data.bundle_4,
  };

  if (window.analytics) {
    window.analytics.track(action, properties);
  }
};

export const analyticsClickedMoreDetails = (data = {}) => {
  const action = 'Clicked More Details on a service';
  const defaultData = getDefaultAnalyticsData();

  const properties = {
    ...defaultData,
    hasDvr: data.hasDvr,
    company_name: data.company_name || '',
    name: data.name || '',
    cost_for_dvr: data.cost_for_dvr || '',
    cost: data.cost || '',
    original_cost: data.original_cost || '',
    app_id: data.app_id || '',
    channels: data.channels || '',
    service_id: data.service_id || '',
    service_type_id: data.service_type_id || '',
    streams: data.streams || '',
    open_or_close: data.open_or_close,
  };

  if (window.analytics) {
    window.analytics.track(action, properties);
  }
};

export const analyticsOpenedLearnMoreModal = (data = {}) => {
  const action = 'Opened Modal';
  const defaultData = getDefaultAnalyticsData();

  const properties = {
    ...defaultData,
    slot: data.slot || '',
    bundle_id: data.bundle_id || '',
    partner: data.partner || '',
    partner_id: data.partner_id || defaultData.partner_id,
    unique_id: data.unique_id || defaultData.unique_id,
  };

  if (window.analytics) {
    window.analytics.track(action, properties);
  }
};

export const analyticsClosedLearnMoreModal = (data = {}) => {
  const action = 'Closed Modal';
  const defaultData = getDefaultAnalyticsData();

  const properties = {
    ...defaultData,
    slot: data.slot || '',
    bundle_id: data.bundle_id || '',
    partner: data.partner || '',
    partner_id: data.partner_id || defaultData.partner_id,
    unique_id: data.unique_id || defaultData.unique_id,
  };

  if (window.analytics) {
    window.analytics.track(action, properties);
  }
};

export const analyticsOpenedChannelsModal = (data = {}) => {
  const action = 'Opened Channels Modal';
  const defaultData = getDefaultAnalyticsData();

  const properties = {
    ...defaultData,
    slot: data.slot || '',
    bundle_id: data.bundle_id || '',
    partner: data.partner || '',
    partner_id: data.partner_id || defaultData.partner_id,
    unique_id: data.unique_id || defaultData.unique_id,
  };

  if (window.analytics) {
    window.analytics.track(action, properties);
  }
};

export const analyticsClosedChannelsModal = (data = {}) => {
  const action = 'Closed Channels Modal';
  const defaultData = getDefaultAnalyticsData();

  const properties = {
    ...defaultData,
    slot: data.slot || '',
    bundle_id: data.bundle_id || '',
    partner: data.partner || '',
    partner_id: data.partner_id || defaultData.partner_id,
    unique_id: data.unique_id || defaultData.unique_id,
  };

  if (window.analytics) {
    window.analytics.track(action, properties);
  }
};

export const analyticsGetResultsFromEmail = (data = {}) => {
  const action = 'Get Results From Email Form';
  const defaultData = getDefaultAnalyticsData();

  const properties = {
    ...defaultData,
    found: 'true',
    email: data.email,
  };

  if (window.analytics) {
    window.analytics.track(action, properties);
  }
};

export const analyticsResultsChannels = (data = {}) => {
  const action = 'The Channels';
  const defaultData = getDefaultAnalyticsData();

  const properties = {
    ...defaultData,
    value: data.value,
  };

  if (window.analytics) {
    window.analytics.track(action, properties);
  }
};

export const analyticsFormResults = (data = {}) => {
  const action = 'Form Results';
  const defaultData = getDefaultAnalyticsData();

  const properties = {
    ...defaultData,
    category: 'Channel Clicked',
    value: data.value,
  };

  if (window.analytics) {
    window.analytics.track(action, properties);
  }
};

export const analyticsMobileCardTrack = (action, data = {}) => {
  const defaultData = getDefaultAnalyticsData();

  const properties = {
    ...defaultData,
    bundle_id: data.bundle_id || '',
    open_or_close: data.open_or_close,
    slot: data.slot || '',
  };

  if (window.analytics) {
    window.analytics.track(action, properties);
  }
};

export const analyticsSimilarContentModal = (action, data = {}) => {
  const defaultData = getDefaultAnalyticsData();

  const properties = {
    ...defaultData,
    slot: data.slot || '',
    bundle_id: data.bundle_id || '',
    partner: data.partner || '',
    partner_id: data.partner_id || defaultData.partner_id,
    unique_id: data.unique_id || defaultData.unique_id,
  };

  if (window.analytics) {
    window.analytics.track(action, properties);
  }
};

export const analyticsClickingLearnMoreModal = (data = {}) => {
  const action = 'Clicking Learn More from Modal';

  const properties = {
    app_id: data.app_id || '',
    service_name: data.service_name || '',
    location: data.location || '',
    slot: data.slot || '',
    bundle_id: data.bundle_id || '',
    service_id: data.service_id || '',
    href: data.href || '',
  };

  customAnalyticsEvent(action, properties);
};

/* INTERNET OFFERS EVENTS */

export const analyticsEnteredAddress = (data = {}) => {
  const action = 'Entered Address';
  const defaultData = getDefaultAnalyticsData();

  const properties = {
    zip: data.zipcode,
    address: data.address,
    unique_id: data.unique_id || defaultData.unique_id,
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsStartLoadOffers = (data = {}) => {
  const action = 'Start Load Offers';

  const properties = {
    address: data.address,
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsOffersLoaded = (data = {}) => {
  const action = 'Offers Loaded';

  const properties = {
    number_of_offers_displayed: data.number_of_offers_displayed,
    time_loaded: data.time_loaded,
    providers: data.providers,
    database_id: data.database_id,
  };

  customAnalyticsEvent(action, properties);
};

/* WATCH NEXT EVENTS */

export const analyticsStartedWNForm = (data = {}) => {
  const action = 'Started Watch Next Form';

  const properties = {
    id: data.id,
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsSelectedServices = (data = {}) => {
  const action = 'Selected Services';

  const properties = {
    id: data.id,
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsSelectedGenres = (data = {}) => {
  const action = 'Selected Genres';

  const properties = {
    id: data.id,
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsNotInterested = (data = {}) => {
  const action = 'Clicked Seen/Not Interested';

  const properties = {
    ...data,
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsClickedShareButton = (data = {}) => {
  const action = 'Clicked Share Button';

  const properties = {
    id: data.id,
    share_type: data.share_type, // (fb, twitter, sms, ...)
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsVoted = (data = {}) => {
  const action = 'Voted';

  const properties = {
    id: data.id,
    showId: data.showId,
    vote_type: data.vote_type, // up or down
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsRecommendedShow = (data = {}) => {
  const action = 'Recommended Show To Friend';

  const properties = {
    id: data.id,
    show_name: data.show_name,
  };

  customAnalyticsEvent(action, properties);
};

/* SEARCH EVENTS */

export const analyticsSearched = (data = {}) => {
  const action = 'Searched';
  const state = store.getState();

  const user = state.auth.user;
  const partner = state.partner.data;
  const anonymousId = state.auth.anonymousId;

  const properties = {
    annonymous_id: anonymousId,
    query: data.query || '',
    numberOfItemsDisplayed: data.numberOfItemsDisplayed || 0,
    first_item_type: data.first_item_type || '',
    first_item_name: data.first_item_name || '',
    first_item_id: data.first_item_id || '',
    search_location: data.search_location || '',
    page: window.location?.pathname || '',
    partner_id: data.partner_id || partner.id || 0,
    user_id: data.user_id || user?.id || '',
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsChooseSearchItem = (data = {}) => {
  const action = 'Choose Search Item';
  const state = store.getState();

  const user = state.auth.user;
  const partner = state.partner.data;
  const unique_id = state.auth.unique_id;
  const anonymousId = state.auth.anonymousId;

  const properties = {
    annonymous_id: anonymousId,
    unique_id: unique_id || '',
    query: data.query || '',
    numberOfItemsDisplayed: data.numberOfItemsDisplayed || 0,
    type: data.type || '',
    title: data.title || '',
    id: data.id || '',
    positionOfItem: data.positionOfItem || '',
    search_location: data.search_location || '',
    page: window.location?.pathname || '',
    partner_id: data.partner_id || partner.id || 0,
    user_id: data.user_id || user?.id || '',
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsLeftSearch = (data = {}) => {
  const action = 'Left Search';
  const state = store.getState();

  const user = state.auth.user;
  const partner = state.partner.data;
  const anonymousId = state.auth.anonymousId;

  const properties = {
    annonymous_id: anonymousId,
    query: data.query || '',
    numberOfResults: data.numberOfResults || 0,
    search_location: data.search_location || '',
    page: window.location?.pathname || '',
    partner_id: data.partner_id || partner.id || 0,
    user_id: data.user_id || user?.id || '',
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsNoSearchResults = (data = {}) => {
  if (!data.query) {
    return;
  }

  const action = 'No Search Results';
  const state = store.getState();

  const user = state.auth.user;
  const partner = state.partner.data;
  const anonymousId = state.auth.anonymousId;

  const properties = {
    annonymous_id: anonymousId,
    query: data.query || '',
    search_location: data.search_location || '',
    page: window.location?.pathname || '',
    partner_id: data.partner_id || partner.id || 0,
    user_id: data.user_id || user?.id || '',
  };

  customAnalyticsEvent(action, properties);
};

/* SHOW AND MOVIES */

export const analyticsClickedAppToFilterContent = (data = {}) => {
  const action = 'Clicked App To Filter Content';
  const state = store.getState();

  const partner = state.partner.data;
  const anonymousId = state.auth.anonymousId;

  const properties = {
    annonymous_id: anonymousId,
    app_name: data.app_name || '',
    app_id: data.app_id || '',
    app_index: data.app_index ?? '',
    app_selected: data.app_selected ?? '',
    page: window.location?.pathname || '',
    partner_id: data.partner_id || partner.id || 0,
  };

  customAnalyticsEvent(action, properties);
};

/* SUBSCRIPTION EVENTS */

export const analyticsCreatedSubscriptionAccount = (data = {}) => {
  const action = 'Created Subscription Account';

  const properties = {
    location: data.location || '',
    app_id: data.app_id || '',
    product_id: data.product_id || '',
    plan_id: data.plan_id || '',
    addons: data.addons || '',
    email: data.email || '',
    zip_code: data.zip_code || '',
    price: data.price || '',
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsStartedSubscriptionCheckout = (data = {}) => {
  const action = 'Started Subscription Checkout';

  const properties = {
    location: data.location || '',
    app_id: data.id || '',
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsSelectPlan = (data = {}) => {
  const action = 'Selected Plan';

  const properties = {
    location: data.location || '',
    product_id: data.product_id || '',
    product_name: data.product_name || '',
    plan_id: data.plan_id || '',
    plan_name: data.plan_name || '',
    plan_price: data.plan_price || '',
    previous_plan_id: data.previous_plan_id || '',
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsClickedintoAddonSelectBox = (data = {}) => {
  const action = 'Clicked into Addon Select Box';

  const properties = {
    location: data.location || '',
    product_id: data.product_id || '',
    plan_id: data.plan_id || '',
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsClickedAddon = (data = {}) => {
  const action = 'Clicked Addon';

  const properties = {
    location: data.location || '',
    product_id: data.product_id || '',
    product_name: data.product_name || '',
    plan_id: data.plan_id || '',
    addon_id: data.addon_id || '',
    addon_name: data.addon_name || '',
    addon_price: data.addon_price || '',
    add_or_remove: data.add_or_remove || '',
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsChoosePlanAndAddons = (data = {}) => {
  const action = 'Choose Plan and Addons';

  const properties = {
    location: data.location || '',
    app_id: data.app_id || '',
    product_id: data.product_id || '',
    plan_id: data.plan_id || '',
    addons: data.addons || '',
    price: data.price || '',
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsCompletedSubscriptionSignUp = (data = {}) => {
  const action = 'Completed Subscription Sign Up';

  const properties = {
    location: data.location || '',
    app_id: data.app_id || '',
    product_id: data.product_id || '',
    plan_id: data.plan_id || '',
    addons: data.addons || '',
    email: data.email || '',
    zip_code: data.zip_code || '',
    price: data.price || '',
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsSubscriptionSpecOffer = (data = {}) => {
  const action = 'Subscription Special Offer';

  const properties = {
    location: data.location || '',
    app_id: data.app_id || '',
    claim_special_offer: data.claim_special_offer,
  };

  customAnalyticsEvent(action, properties);
};

export const analyticsContactUsClick = (data = {}) => {
  const action = 'Contact Us Click';

  const properties = {
    location: data.location || '',
    email: data.email || '',
  };

  customAnalyticsEvent(action, properties);
};

export default defAnalyticsData;
