export const LOCATIONS_IN_BUNDLE = 'in a bundle';
export const LOCATIONS_REPLACE_MISSING = 'other app you may like';
export const LOCATIONS_YOU_MAY_LIKE = 'replace your missing channels';
export const LOCATIONS_APP_PAGE = 'app page';
export const LOCATIONS_HEADER = 'header';
export const LOCATIONS_FOOTER = 'footer';
export const LOCATIONS_MOBILE_SIDE_BAR = 'mobile sidebar';
export const LOCATIONS_PROFILE_SIDE_BAR = 'profile sidebar';
export const LOCATIONS_SLOT = 'slot';
export const LOCATIONS_SEARCH_BAR = 'search bar';
export const LOCATIONS_IN_CONTENT = 'in content';
export const LOCATIONS_ABOUT_PAGE = 'about page';
export const LOCATIONS_CHANNELS_PAGE = 'channels page';
export const LOCATIONS_CHANNELS_CSR_PAGE = 'channels csr page';
export const LOCATIONS_CHANNELS_EMAIL_PAGE = 'channels email page';
export const LOCATIONS_DISCOVER_PAGE = 'discover page';
export const LOCATIONS_DISCOVER_SEARCH_PAGE = 'discover search page';
export const LOCATIONS_DISCOVER_TUTORIAL_PAGE = 'discover tutorial page';
export const LOCATIONS_CHANNEL_PAGE = 'Channel page';
export const LOCATIONS_DISNEY_PLUS_BUNDLE_PAGE = 'disney plus bundle page';
export const LOCATIONS_HOME_PAGE = 'home page';
export const LOCATIONS_HOME_AUTH_PAGE = 'home logged in page';
export const LOCATIONS_HOME_PARTNER_PAGE = 'partner home page';
export const LOCATIONS_HOW_IT_WORKS_PAGE = 'how it works page';
export const LOCATIONS_INTERNET_OFFERS_PAGE = 'internet offers page';
export const LOCATIONS_INTERNET_OFFERS_QUESTIONS_PAGE = 'internet offers questions page';
export const LOCATIONS_INTERNET_OFFERS_RESULTS_PAGE = 'internet offers results page';
export const LOCATIONS_MOVIE_DETAILS_PAGE = 'movie details page';
export const LOCATIONS_SHOW_DETAILS_PAGE = 'show details page';
export const LOCATIONS_NOT_FOUND_PAGE = 'not found page';
export const LOCATIONS_PLAN_DETAILS_PAGE = 'plan details page';
export const LOCATIONS_MOVIES_WL_PAGE = 'movies watchlist page';
export const LOCATIONS_SHOWS_WL_PAGE = 'shows watchlist page';
export const LOCATIONS_WL_PAGE = 'watchlist page';
export const LOCATIONS_WL_SHARED_PAGE = 'watchlist shared page';
export const LOCATIONS_MY_APPS_HAVE_PAGE = 'my apps have page';
export const LOCATIONS_PROFILE_MY_RESULTS_PAGE = 'profile my results page';
export const LOCATIONS_PROFILE_WATCHLISTS = 'profile watchlists page';
export const LOCATIONS_PROFILE_WATCHLIST_DETAILS = 'profile watchlist details page';
export const LOCATIONS_PROFILE_SUBSCRIPTION_DETAILS = 'profile subscription details page';
export const LOCATIONS_SUBSCIPTION_PAGE = 'subscription page';
export const LOCATIONS_SUBSCIPTION_FORM_PAGE = 'subscription form page';
export const LOCATIONS_WN_PAGE = 'watch next page';
export const LOCATIONS_WN_RESULTS_PAGE = 'watch next results page';
export const LOCATIONS_WN_TUTORIAL_PAGE = 'watch next tutorial page';
export const LOCATIONS_WN_PROFILE_PAGE = 'watch next profile page';
export const LOCATIONS_RESULTS_PAGE = 'results page';
export const LOCATIONS_RESULTS_CSR_PAGE = 'results csr page';
export const LOCATIONS_SHOWS_AND_MOVIES_PAGE = 'shows and movies page';
export const LOCATIONS_SHOWS_AND_MOVIES_DISCOVER_PAGE = 'shows and movies discover page';
export const LOCATIONS_STARRY_PAGE = 'starry page';
export const LOCATIONS_T_AND_C_CURIOSITY_PAGE = 'TAndC curiosity page';
export const LOCATIONS_START_PAGE = 'start page';
export const LOCATIONS_START_CSR_PAGE = 'start csr page';
export const LOCATIONS_WP_POST_PAGE = 'wp post page';
export const LOCATIONS_WP_POST_POPUP = 'wp post popup';
export const LOCATIONS_WP_CATEGORY_PAGE = 'wp category page';
export const LOCATIONS_WP_AUTHOR_PAGE = 'wp author page';
export const LOCATIONS_WP_INSIGHTS_PAGE = 'wp insights page';
export const LOCATIONS_WP_NEWS_PAGE = 'wp news page';
export const LOCATIONS_ONBOARDING_PAGE = 'onboarding page';
export const LOCATIONS_EMMY_AWARDS_PAGE = 'emmy awards page';
export const LOCATIONS_BREADCRUMBS = 'breadcrumbs';
export const LOCATIONS_ON_CARD = 'on card';
export const LOCATIONS_ON_PAGE = 'on page';
export const LOCATIONS_HOVER = 'hover';
export const LOCATIONS_MODAL = 'modal';
export const LOCATIONS_DETAIL_PAGE = 'detail page';
export const LOCATIONS_CONTENT_HOVER = 'content hover';
export const LOCATIONS_EPISODE_HOVER = 'episode hover';
export const LOCATIONS_CONTENT_MODAL = 'content modal';
export const LOCATIONS_SEARCH_PAGE = 'search page';
export const LOCATIONS_PROPMOTION_PAGE = 'promotion page';
export const LOCATIONS_FAQ_PAGE = 'faq page';
export const LOCATIONS_TERMS_CONDITIONS_PAGE = 'Terms ans conditions page';
export const LOCATIONS_PRIVACY_POLICY_PAGE = 'Privacy policy page';
export const LOCATIONS_CONFIRMATION_ISP_PAGE = 'isp confirmation page';
export const LOCATIONS_SHARE_PAGE = 'share page';
export const LOCATIONS_BUSINESS_APPS_PAGE = 'business apps page';
export const LOCATIONS_STREAMING_REWARDS_PAGE = 'streaming rewards page';

export const LOCATIONS_STREAMING_REWARDS_CLAIM_PAGE = 'streaming rewards claim page';
export const LOCATIONS_STREAMING_REWARDS_FUNDS_PAGE = 'streaming rewards funds page';
export const LOCATIONS_STREAMING_REWARDS_INSTRUCTION_PAGE = 'streaming rewards instruction page';
export const LOCATIONS_CAREERS_PAGE = 'careers page';
export const LOCATIONS_POSITION_PAGE = 'position page';
export const LOCATIONS_CONTACT_US_PAGE = 'contact us page';
export const LOCATIONS_UNSUBSCRIBE_PAGE = 'unsubscribe page';
export const LOCATIONS_CHANNEL_LINEUP_PAGE = 'channel lineup page';
export const LOCATIONS_PARTNER_TV_PAGE = 'partner tv page';
export const LOCATIONS_FIND_BEST_SLING_PACKAGE_PAGE = 'find best sling package page';
export const LOCATIONS_FREE_TV_LANDING_PAGE = 'free tv landing page';

// COMPONENTS
export const LOCATIONS_SLING_BANNER = 'Sling TV Banner';
export const LOCATIONS_SLING_PROMO_BANNER = 'Sling TV Promo Banner';
export const LOCATIONS_SLING_PROMO_PEACOCK_BANNER = 'Sling TV Promo Peacock Banner';
export const LOCATIONS_HULU_LIVE_BANNER = 'Hulu Live Banner';
